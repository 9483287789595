import React from "react"
import { contactFormSchema } from "./formSchema"

function Form() {
  const [name, setName] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [storedUser, setStoredUser] = React.useState("")
  const [showForm, setShowForm] = React.useState(true)
  const [isPending, setIsPending] = React.useState(false)
  const [results, setResults] = React.useState()
  const [resultType, setResultType] = React.useState()
  const [styling, setStyling] = React.useState({})

  const isValid = contactFormSchema.isValidSync({ name, email })
  const isBrowser = typeof window !== "undefined"

  const clearForm = () => {
    setName("")
    setEmail("")
  }

  React.useEffect(() => {
    if (resultType == "success") {
      setStyling({ color: "green" })
    } else if (resultType == "fail") {
      setStyling({ color: "red" })
    }
  }, [resultType, styling, setStyling])

  React.useEffect(() => {
    const user = localStorage.getItem("name")
    if (user) {
      setStoredUser(JSON.parse(user))
      setShowForm(false)
    }
  }, [])

  React.useEffect(() => {
    return () => {
      clearForm()
    }
  }, [])

  const handleSubmit = async e => {
    e.preventDefault()

    console.log("?")
    const data = {
      name,
      email,
    }

    const submitAction = fetch("/api/form", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "content-type": `application/json`,
      },
    })

    if (!isValid) return

    if (isBrowser) {
      window.dataLayer.push({ event: "formSubmitted", formName: "Signup" })
    }

    await submitAction
      .then(res => {
        if (res.status === 200) {
          console.log("success")
          setStoredUser(name)
          setShowForm(false)
          setResults(
            "Thank you for signing up, please check your inbox and confirm you want more info"
          )
          setResultType("sucess")
          localStorage.setItem("name", JSON.stringify(name))
          clearForm()
        }
      })
      .catch(err => {
        // TODO: handle err
        setResults("There was an issue submitting this form")
        setResultType("fail")
        console.log("There was an issue submitting this form", err)
      })
  }

  return (
    <section className="get-course">
      <form id="to-form">
        <h3>Get the Gatsby crash course!</h3>
        <p style={{ textAlign: "center", ...styling }}>{results}</p>
        <div className='inputs-button'>
          <input
            type="text"
            placeholder="FIRST NAME"
            onChange={e => setName(e.target.value)}
            value={name}
          ></input>
          <input
            type="text"
            placeholder="EMAIL ADDRESS"
            onChange={e => setEmail(e.target.value)}
            value={email}
          ></input>
          <button onClick={handleSubmit} disabled={!isValid}>
            START NOW
          </button>
        </div>
      </form>
    </section>
  )
}

export default Form
