import "./styles.css"

import React from "react"
import joshowens from "../assets/josh-owens.png"
import reactjs from "../assets/newReact.png"
import natGeo from "../assets/natGeo.png"
import stateofjs from "../assets/newStateOfJvascript.png"
import thespookiestdev from "../assets/thespookiestdev.png"
import wesbosimage from "../assets/wes-bos-image.jpeg"
import wesbos from "../assets/wes-bos.png"
import Form from "../components/Form"
import { Seo } from "../components/Seo"

export default function Home() {
  return (
    <>
      <Seo />
      <header>
        <div className="flex">
          <div className="landing-content">
            <h1 className="why-gatsby">Why Gatsby? <span className="white">A react static site generator</span></h1>

            <p className="landing-text-one">
              <span className="orange">Gatsby</span> is a game-changing framework for{" "}
              <span className="orange">rapid software development</span> with
              JavaScript. It combines the power of a React app with GraphQL for data
              fetching to build out{" "}
              <span className="orange">blazing fast static websites</span>.
            </p>

            <a href="#to-form" className="crash-course-link">
              Get the Why Gatsby crash course!
            </a>
          </div>
        </div>
      </header>
      <div className="content-wrapper">
        <section className="what-is">
          <h2 className="orange">What is Gatsby?</h2>
          <p>
            It is a React-based, GraphQL-powered, static site generator that
            feels like a modern open source front-end framework. It uses popular
            technologies including React, Webpack, GraphQL, ES6+, JavaScript,
            and CSS for a smooth development experience that can take your
            website from zero to deployed in just a few hours, with incredible
            performance and excellent security.
          </p>
        </section>
        <section className="reasons">
          <h3>5 Reasons to Build With Gatsby</h3>
          <ol>
            <li>
              It allows for an incredibly smooth web development
              experience--take your idea from zero to deployed react app with static pages in just a few
              hours!
            </li>
            <li>
              It boasts robust documentation despite being relatively new framework
            </li>
            <li>
              Gatsby.js has well-documented data source plugins make it a breeze to
              fetch data from APIs, databases, CMSs, local markdown files, and more
            </li>
            <li>
              It allows developers to generate pages with React source code while still
              getting server-side rendering and static HTML, improving search engine
              optimization and speed
            </li>
            <li>
              It includes built-in linting rules to help improve your
              website's accessibility, easy tooling for building progressive web apps, code splitting, lazy loading support and much more in the plugin directory.
            </li>
          </ol>
        </section>

        <section className="misconceptions">
          <h3 className="orange">Common Misconceptions About Gatsby</h3>
          <span>Will it be difficult to get started with this static site generator?</span>

          <p>
            Some frameworks and libraries can be difficult to start with, but it boasts excellent documentation with quick-start guides for
            developers at any level of experience to get your static site
            template up and running within just a few minutes, including React
            components, and detailed explanations for implementation of Gatsby's
            data sourcing plugins.
          </p>

          <span>
            What if I don't have experience with React and/or GraphQL?
          </span>

          <p>
            It may seem that a lack of experience with React, GraphQL, or both,
            would be a barrier to entry. However, despite its relative newness,
            Gatsby has extremely robust documentation with examples with React
            and GraphQL, making it a great learning tool for both.
          </p>

          <span>
            I already have a webpack config/static site generator that works for
            me!
          </span>

          <p>
            Gatsby.js allows developers to build products that combine the speed
            and performance of a static site with all of the benefits and
            flexibility of modern web front-end technologies, plus GraphQL to fetch
            data from a variety of sources for an exceptional developer and user
            experience.
          </p>

          <span>GraphQL data collection sounds intimidating!</span>

          <p>
            The framework offers data source plugins that are well-documented, and in many cases
            require only a few lines of code to configure.
          </p>
        </section>

        <section className="others-say">
          <h3 className="orange">What Others Say About Gatsby</h3>
          <div className="tweets">
            <div className="tweet">
              <a
                href="https://twitter.com/kurtkemple/status/1257755485215887365"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="twitter-user"
                  src={thespookiestdev}
                  alt="avatar, name, and handle for user @kurtkemple on Twitter"
                />
                <h5>
                  I used <span className="blue">@gatsbyjs</span> recipes for the
                  first time today and it was awesome.
                </h5>
              </a>
            </div>
            <div className="tweet">
              <a
                href="https://twitter.com/wesbos/status/1250840730634870784"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="twitter-user"
                  src={wesbos}
                  alt="avatar, name, and handle for user @wesbos on Twitter"
                />
                <h5>
                  I spent about 45 mins resizing a few images and cleaning up
                  some markup to get this score.
                  <br></br>
                  <br></br>
                  This is why I love modern tools like{" "}
                  <span className="blue">@gatsbyjs</span>, it's not hard to make
                  a good website. I didn't have to do any heavy lifting myself
                </h5>
                <img
                  className="wesbosimg"
                  src={wesbosimage}
                  alt="representation of scores of performance (99), accessibility (100), best practices (100), and SEO (100) for Gatsby"
                />
              </a>
            </div>
            <div className="tweet">
              <a
                href="https://twitter.com/joshowens/status/1316764459310944256"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="twitter-user"
                  src={joshowens}
                  alt="avatar, name, and handle for user @joshowens on Twitter"
                />
                <h5>
                  Man, <span className="blue">@GatsbyJS</span> is such a great
                  way to get sites up quickly with{" "}
                  <span className="blue">@reactjs</span>! I think we've built
                  and deployed like 4 sites this month so far 😆
                </h5>
              </a>
            </div>
          </div>
        </section>

        <section className="sites-built">
          <h3 className="orange">Here are some Gatsby Sites</h3>
          <div className="sites">
            <a href="https://reactjs.org/" target="_blank" rel="noreferrer">
              <img
                className="reactjs-preview"
                src={reactjs}
                alt="preview of the React.js homepage"
              />
            </a>
            <a
              href="https://www.nationalgeographic.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={natGeo}
                alt="preview of the National Geographic homepage"
              />
            </a>
            <a href="https://stateofjs.com/" target="_blank" rel="noreferrer">
              <img
                src={stateofjs}
                alt="preview of the State of JavaScript homepage"
              />
            </a>
          </div>
        </section>

        <section className="recap">
          <h3 className="orange">Gatsby.js — A Game-Changer</h3>
          <p>
            While it is still a new technology, and it is constantly growing and
            improving. However, a few things are certain:
          </p>

          <ul>
            <li>
              <strong>The framework is simple to get started with</strong>—Templates,
              gatsby plugins, and excellent documentation make it fun to build with—for
              developers at any experience level, making it easy to build for web and mobile
            </li>

            <li>
              <strong>Data-fetching has never been easier</strong>— the
              well-documented data plugins make the process of fetching data at build time for
              your static pages on your website smooth and elegant
            </li>

            <li>
              <strong>It's the best of both worlds</strong>—the framework makes it easy
              to build websites and landing pages using modern technologies like React and GraphQL
              without sacrificing speed and performance offered by generated static HTML
            </li>
          </ul>
          <p>Find out if Gatsby is right for you by signing up below.</p>
        </section>

        <hr></hr>

        <Form />

        <footer>© Kickstand, 2022.</footer>
      </div>
    </>
  )
}
