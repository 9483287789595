import React from "react"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

export const Seo = ({ description, keywords, title, url, author }) => {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description
        const metaTitle = title || data.site.siteMetadata.title
        const metaUrl = url || data.site.siteMetadata.url
        const metaAuthor = author || data.site.siteMetadata.author
        const metaKeywords = keywords || [
          "gatsby blog",
          "why gatsby",
          "gatsby crash course",
          "learn gatsby",
          "gatsby development",
        ]
        return (
          <Helmet
            title={title}
            meta={[
              {
                name: "description",
                content: metaDescription,
              },
              {
                property: "og:title",
                content: metaTitle,
              },
              {
                property: "og:description",
                content: metaDescription,
              },

              {
                property: "og:url",
                content: metaUrl,
              },
              {
                name: "twitter:card",
                content: "summary_large_image",
              },
              {
                property: "twitter:creator",
                content: metaAuthor,
              },
              {
                property: "twitter:title",
                content: metaTitle,
              },
              {
                property: "twitter:description",
                content: metaDescription,
              },
            ].concat(
              metaKeywords && metaKeywords.length > 0
                ? {
                    name: "keywords",
                    content: metaKeywords.join(", "),
                  }
                : []
            )}
          >
            <html lang="en" />
            <title>{metaTitle}</title>
            <description>{metaDescription}</description>
            <meta name="description" content={metaDescription} />
            <meta name="title" content={metaTitle} />
          </Helmet>
        )
      }}
    />
  )
}

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
