import { object, string } from "yup"

export const contactFormSchema = object({
  name: string()
    .required("Please enter your name")
    .min(3, "Name must be at least 3 characters long"),
  email: string()
    .email("Please enter a valid email")
    .required("Please enter an email"),
})
